import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { FaCheckDouble } from "react-icons/fa";
import { MdVisibility as EditSvg } from "react-icons/md";

import HeaderContainer from "../../components/HeaderContainer";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import PageContainer from "../../components/PageContainer";
import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";
import TableBig from "../../components/TableBig";
import useToggle from "../../hooks/useToggle";

import ContainerModal from "./ContainerModal";
import { getColumns, getTableData } from "./actions";

const Approval: React.FC = () => {
  const [loading, toggleLoading] = useToggle(true);
  const [tableData, setTableData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clickedRow, setClickedRow] = useState<any>({});

  const columns = useMemo(() => {
    const baseCols = getColumns();

    baseCols[baseCols.length - 1].Cell = ({ row }) => (
      <div
        onClick={() => {
          setClickedRow(row);
          setOpenModal(true);
        }}
      >
        <EditSvg size={16} style={{ cursor: "pointer" }} />
      </div>
    );

    return baseCols;
  }, []);

  const getInitialState = useCallback(() => {
    return getTableData((data) => {
      if (data.error) return console.error(data.message);
      setTableData(data.tableData);
      toggleLoading();
    });
  }, [toggleLoading]);

  useLayoutEffect(() => {
    loading && getInitialState();
  }, [loading, getInitialState]);

  if (loading) return <LoadingScreen />;
  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle icon={<FaCheckDouble />} title="Aprovação" />
      </HeaderContainer>
      <Paper>
        <TableBig
          data={tableData}
          columns={columns}

          // openModal={(row) => {
          //   setClickedRow(row);
          //   setOpenModal(true);
          // }}
        />
        <Modal
          containerStyle={{
            maxHeight: "80vh",
            overflowY: "auto",
          }}
          open={openModal}
          setOpen={() => setOpenModal(!openModal)}
          children={
            <ContainerModal
              clickedRow={clickedRow}
              updatePageState={() => {
                toggleLoading();
                setOpenModal(false);
              }}
            />
          }
        />
      </Paper>
    </PageContainer>
  );
};

export default Approval;
