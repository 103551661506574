import { useState } from "react";
import Status from "../../../../../components/Status";

export const getColumns = () => {
  return [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Solicitante",
      accessor: "requester",
    },
    {
      Header: "Origem Base",
      accessor: "base_origin",
    },
    {
      Header: "Filial",
      accessor: "filial",
    },
    {
      Header: "Adicionais",
      accessor: "adicionais",
      Cell: ({ row }: any) => {
        const [isTooltipVisible, setTooltipVisible] = useState(false);

        return (
          <div
            className="pre-tooltip"
            style={{
              position: "relative",
              display: "inline-block",
              maxWidth: "100%",
            }}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            <span
              style={{
                display: "inline-block",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.original.adicionais}
            </span>
            {isTooltipVisible && (
              <div
                style={{
                  visibility: isTooltipVisible ? "visible" : "hidden",
                  backgroundColor: "black",
                  color: "#fff",
                  textAlign: "center",
                  borderRadius: "5px",
                  padding: "5px",
                  position: "absolute",
                  zIndex: 1,
                  bottom: "125%" /* Posiciona acima do texto */,
                  left: "50%",
                  transform: "translateX(-50%)",
                  whiteSpace: "nowrap",
                  maxWidth: "200px" /* Largura máxima do tooltip */,
                  opacity: isTooltipVisible ? 1 : 0,
                  transition: "opacity 0.3s",
                }}
              >
                {row.original.adicionais}
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Observação",
      accessor: "observation",
    },
    {
      Header: "Status",
      accessor: ({ row }: any) => <Status status={row.status} />,
    },
    {
      Header: "Data de solicitação",
      accessor: "date_request",
    },
    {
      Header: "Histórico",
      accessor: "edit",
    },
  ];
};
