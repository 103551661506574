import { Box } from "@material-ui/core";
import { memo, useCallback, useRef, useState } from "react";
import XLSX from "xlsx";

import AlertModal from "../../../../components/AlertModal";
import Button from "../../../../components/Button";
import LoadingModal from "../../../../components/LoadingModal";
import { useAuth } from "../../../../contexts/AuthContext";
import api from "../../../../services/api";

interface IProps {
  columns: any;
  tableData: any;
  clickedRow: any;
  updatePageState: any;
}

const Buttons: React.FC<IProps> = ({
  columns,
  tableData,
  clickedRow,
  updatePageState,
}) => {
  const { user, isUserSupport } = useAuth();

  const blockActions = useCallback(() => {
    console.log({status: clickedRow.original.status})
    if (!user) return true;
    if (Object.keys(user).length === 0) return true;
    if (/master/i.test(user.profile_type)) return false;
    if (/^Aguardando.*N2/i.test(clickedRow.original.status) && 
    !user.permissions.includes("edit_approval_n2")) return true
    return (
      /^(?!Aguardando).*/i.test(clickedRow.original.status) ||
      isUserSupport ||
      clickedRow.original.base.users.creation.user.id === user.id
    );
  }, [
    clickedRow.original.base.users.creation.user.id,
    clickedRow.original.status,
    isUserSupport,
    user,
  ]);

  const isloadingRef = useRef<any>(null);
  const rejectRef = useRef<any>(null);
  const approveRef = useRef<any>(null);
  const defaultErrorRef = useRef<any>(null);
  const alreadyProcessingErrorRef = useRef<any>(null);

  const openLoading = useCallback((message: string, promise: any) => {
    isloadingRef.current.open(message, promise);
  }, []);

  const toggleReject = useCallback(() => {
    rejectRef.current.toggle();
  }, []);

  const toggleApprove = useCallback(() => {
    approveRef.current.toggle();
  }, []);

  const toggleDefaultError = useCallback(() => {
    defaultErrorRef.current.toggle();
  }, []);

  const toggleAlreadyProcessingError = useCallback(() => {
    alreadyProcessingErrorRef.current.toggle();
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const onApprove = useCallback(() => {
    setIsLoading(true);
    const request = [{ id: clickedRow.original.base.id }];

    openLoading(
      "Aprovando...",
      api
        .post("/price/service-orders/approve", request)
        .then(() => {
          toggleApprove();
        })
        .catch((error) => {
          if (
            error.response &&
            /processando/i.test(error.response.data.message)
          ) {
            toggleAlreadyProcessingError();
          } else {
            toggleDefaultError();
          }
        })
        .finally(() => setIsLoading(false))
    );
  }, [
    clickedRow.original.base.id,
    openLoading,
    toggleApprove,
    toggleAlreadyProcessingError,
    toggleDefaultError,
  ]);

  const onReject = useCallback(() => {
    setIsLoading(true);
    const request = [{ id: clickedRow.original.base.id }];

    openLoading(
      "Reprovando...",
      api
        .post("/price/service-orders/reject", request)
        .then(() => {
          toggleReject();
        })
        .catch((error) => {
          if (
            error.response &&
            /processando/i.test(error.response.data.message)
          ) {
            toggleAlreadyProcessingError();
          } else {
            toggleDefaultError();
          }
        })
        .finally(() => setIsLoading(false))
    );
  }, [
    clickedRow.original.base.id,
    openLoading,
    toggleReject,
    toggleAlreadyProcessingError,
    toggleDefaultError,
  ]);

  const downloadExcel = useCallback(() => {
    const header = columns
      .map((col: any) => col.Header)
      .filter((col: any) => col !== "Variação %");

    const data = tableData.map((tableRow: any) => {
      const excelRow: any = {};

      columns.forEach((col: any) => {
        if (col.Header === "Variação %") return;
        excelRow[col.Header] = tableRow[col.accessor];
      });

      return excelRow;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(data, { header });

    XLSX.utils.book_append_sheet(wb, ws1);
    XLSX.writeFile(
      wb,
      `Aprovar-ID_${clickedRow.original.id}-Origem_${clickedRow.original.base_origin}-Solicitante_${clickedRow.original.requester}.xlsx`
    );
  }, [columns, tableData, clickedRow]);

  return (
    <Box
      p="3rem 1rem 0"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      {!blockActions() && (
        <Button
          width="200px"
          onClick={onReject}
          containerStyle={{
            backgroundColor: isLoading ? "grey" : "red",
            border: "1px solid red",
          }}
          disabled={isLoading}
        >
          {!isLoading ? "Reprovar" : "Aguarde..."}
        </Button>
      )}
      <Button width="200px" onClick={downloadExcel}>
        Exportar Excel
      </Button>
      {!blockActions() && (
        <Button
          width="200px"
          onClick={onApprove}
          containerStyle={{
            backgroundColor: isLoading ? "grey" : "green",
            border: "1px solid green",
          }}
        >
          {!isLoading ? "Aprovar" : "Aguarde..."}
        </Button>
      )}
      <AlertModal
        ref={rejectRef}
        type="error"
        message="Reprovado com sucesso!"
        onContinue={updatePageState}
      />
      <AlertModal
        ref={approveRef}
        message="Aprovado com sucesso!"
        onContinue={updatePageState}
      />
      <AlertModal
        ref={defaultErrorRef}
        type="error"
        message="Erro, tente novamente mais tarde."
        onContinue={updatePageState}
      />
      <AlertModal
        ref={alreadyProcessingErrorRef}
        type="error"
        message="Soliticação está sendo processada por outro usuário."
        onContinue={updatePageState}
      />
      <LoadingModal ref={isloadingRef} />
    </Box>
  );
};

export default memo(Buttons);
